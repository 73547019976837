import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin: 1rem auto;
    padding: 1rem;
  }
`;

const Title = styled.h1`
  color: #3498db;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  text-decoration: underline;
  transition: color 0.3s ease;

  &:hover {
    color: #2980b9;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 12 12'%3E%3Cpath d='M6 9L1 4h10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 12px;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;

  &:hover {
    background-color: #2980b9;
  }
`;

const OutputGroup = styled.div`
  margin-top: 1.5rem;
  position: relative;
`;

const Output = styled.pre`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  white-space: pre-wrap;
  word-break: break-all;
  font-family: 'Courier New', monospace;
`;

const IPConverter = () => {
  const [input, setInput] = useState('');
  const [protocol, setProtocol] = useState('http');
  const [output, setOutput] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const inputRef = useRef(null);
  const outputRef = useRef(null);

  const handleConvert = () => {
    const lines = input.split('\n');
    const convertedLines = lines.map(line => {
      const [ip, port, username, password] = line.split(':');
      if (ip && port && username && password) {
        return `${protocol}://${username}:${password}@${ip}:${port}`;
      }
      return '';
    }).filter(Boolean);

    setOutput(convertedLines.join('\n'));
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInput(text);
    } catch (err) {
      console.error('无法读取剪贴板内容: ', err);
    }
  };

  const handleCopy = () => {
    if (outputRef.current) {
      navigator.clipboard.writeText(output).then(() => {
        alert('已复制到剪贴板');
      }).catch(err => {
        console.error('复制失败: ', err);
      });
    }
  };

  const handleImport = async () => {
    if (!fileUrl) {
      alert('请输入有效的文件 URL');
      return;
    }

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const text = await response.text();
      setInput(text);
    } catch (error) {
      console.error('导入文件失败:', error);
      alert('导入文件失败，请检查 URL 是否正确');
    }
  };

  return (
    <Container>
      <Title>IP 地址转换器</Title>
      <InputGroup>
        <Label htmlFor="file-url">输入 TXT 文件 URL：</Label>
        <Input
          id="file-url"
          type="text"
          value={fileUrl}
          onChange={(e) => setFileUrl(e.target.value)}
          placeholder="https://example.com/ips.txt"
        />
      </InputGroup>
      <Button onClick={handleImport}>导入文件内容</Button>
      <InputGroup>
        <Label htmlFor="ip-input">输入 IP 地址（每行一个）：</Label>
        <TextArea
          id="ip-input"
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="格式：IP:端口:用户名:密码"
        />
        <TextButton onClick={handlePaste} style={{ marginTop: '0.5rem' }}>
          从剪贴板粘贴
        </TextButton>
      </InputGroup>
      <Select
        id="protocol-select"
        value={protocol}
        onChange={(e) => setProtocol(e.target.value)}
      >
        <option value="http">HTTP</option>
        <option value="socks5">SOCKS5</option>
      </Select>
      <Button onClick={handleConvert}>转换</Button>
      {output && (
        <OutputGroup>
          <h2>转换后的 IP 地址：</h2>
          <Output ref={outputRef}>{output}</Output>
          <TextButton onClick={handleCopy} style={{ marginTop: '0.5rem' }}>
            复制到剪贴板
          </TextButton>
        </OutputGroup>
      )}
    </Container>
  );
};

export default IPConverter;

