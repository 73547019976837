/*
 * @Author       : cuiguiming
 * @Date         : 2023-07-26 17:00:58
 * @LastEditors  : voidcui
 * @LastEditTime : 2025-01-03 18:01:26
 * @Description  : Description
 */

const dataList = [
  {
    title: "Quick Qrcode",
    desc: "用于生成二维码",
    author: "我本人",
    type: "vue",
    path: "/quick-qrcode",
  },
  {
    title: "Recurse Query",
    desc: "用于解析链接上的链接参数",
    author: "我本人",
    type: "react",
    path: "/recurse-query",
  },
//   {
//     title: "ChatGpt",
//     desc: "智能聊天工具",
//     author: "cui",
//     type: "react",
//     herf: "https://chat.voidcui.top",
//   },
// 	{
//     title: "DM",
//     desc: "用于生成大麦bq链接和二维码",
//     author: "cui",
//     type: "react",
//     path: "/dm",
//   },
	{
    title: "某岛特供转链接工具",
    desc: "用于转换不同环境链接和二维码",
    author: "我本人",
    type: "react",
    path: "/ql",
  },
	{
    title: "代理转换工具",
    desc: "用于代理转换链接",
    author: "我本人",
    type: "react",
    path: "/transferProxy",
  },
];


export default dataList;